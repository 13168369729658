import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
//import './App.css';
import me from './images/me.jpg';

class About extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div class="row">
                    <div class="col-sm-7">
                        <h2>About me!</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">

                            <img class="img-fluid rounded mb-3 mb-md-0" src={me} alt="Rob Miracle" />
                    </div>
                    <div class="col-sm-8">
                        <h3>Fun facts with Rob</h3>
                        <dt>Why are you known as "The Omnigeek"?</dt>
                        <dd>To me a geek is someone who is overly passionate about a particular subject. I mean you can be a sci-fi geek, or a fantasy geek, but you can also be a sports geek or a knitting geek too. I geek out over many things... too many to keep track of, so I like to think of myself as the geek of all things!</dd>
                        <dt>Why are sports are a big part of your life?</dt>
                        <dd>While I don't play many any longer, I have always been a huge sports fan. Growing up in Kentucky, it was Kentucky Basketball. The Dallas Cowbows were on TV every week, so they became my favorite foolball team, bringning my love of football in to play. Later we got hooked on hockey, not only as a fan, but both boys played and I coached. Photographing sports though has made me apprecate them all. To be a successful sports photographer you have to learn the sport and when to anticipate key action to get the most dynamic photos. I'm blessed to have had the opportunity to shoot both NCAA Division I sports of all sorts, NHL ice hockey, and the US Women's National Soccer Team. I've also shot several professional equestrian events including the Olympics Horse Trials and the Kentucky Derby.</dd>
                        <dt>What do you do for fun?</dt>
                        <dd>I've been blessed with occupations which rank at the top of my fun list. I love to create something from nothing. I don't get up in the morning to go to work. I get up to create something new and make the world a little bit of a better place. Of course Photography has to be right up there. Beyond that I enjoy hiking, travel, video games, watching a good movie, and spending time with my awesome family.</dd>
                        <dt>What are you the worst at?</dt>
                        <dd>Oh that's an easy one... plumbing. I'm so bad at it I can't hook up a water hose without it leaking... My plubming failures are epic and tales of legand! I guess I need to become more of a fluid dynamics geek.</dd>
                        <dt>Where is your favorite place?</dt>
                        <dd>That's another easy one. Of the places, I've visited, I love Yosemite National Park. That place is just magical. In a close second is Alaska in the Kenai Peninsula area. There are some fantastic National Parks and great wildlife to find there.</dd>
                        <dt>What is your favorite thing you've done?</dt>
                        <dd>Spending time with my wife and raising to fantastic boys is always #1, but that needs to be at the top of everyone's list. That aside, getting the opportunity to photograph the Kentucky Derby has to be my #1 photography goal. I did that in 2016. Second place would be photographing coastal brown bears in Alaska, which I accomplished in 2022!</dd>
                        <dt>What are your upcoming goals?</dt>
                        <dd>Photographicaly, it's hard to believe that after 45 years behind the camera, I still have things to learn, but I do. I look forward to pushing my photography forward into new and better expriences. Adventure wise, it's a hard sell to the fam, but I need to go skydiving. And travel wise, while I have a lofty goal of all the National Parks, Yellowsone and the Grand Tetons are high on my list. I also need to get to Africa on a photo safari.</dd>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );  
  }
}

export default About;