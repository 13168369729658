import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import styled from "styled-components";
import logo from '../images/RobMiracleLogo_reverse_web.webp';

const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  fontSize: "1.5em",
  color: 'white'
};

//                            <li className="nav-item">
//                                <Link to="/contact" style={linkStyle}>CONTACT</Link>
//                            </li>


class Header extends Component {
  render() {
    return (
        <div className="container-fluid header">
            <div className="row">
                <div className="col-sm-4">
                    <h1 className="display-4">
                        <Link to="/">
                            <img className="img-fluid" src={logo} alt="Rob Miracle Photography" />
                        </Link>
                    </h1>
                </div>
                <div className="col-sm-8">
                    <nav className="navbar navbar-expand-sm bg-primary text-white">
                        <ul className="navbar-nav ml-auto">                                               
                            <li className="nav-item">
                                <Link to="/services" style={linkStyle}>SERVICES</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" style={linkStyle}>CONTACT</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" style={linkStyle}>ABOUT</Link>
                            </li>
                            <li className="nav-item text-white">
                                <a class="text-decoration-none text-white" style={linkStyle} href="https://events.robmiracle.com" target="blank" rel="noreferrer">EVENTS</a>
                            </li> 
                            <li className="nav-item text-white">
                                <a class="text-decoration-none text-white" style={linkStyle} href="https://pics.robmiracle.com" target="blank" rel="noreferrer">ORDER&nbsp;PHOTOS</a>
                            </li>
                        </ul>
                    </nav> 
                </div>
            </div>
        </div>
    );  
  }
}

export default Header;