import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import './App.css';
import photography from '../images/photography.webp';
import facebook from '../images/facebook.png';
import instagram from '../images/Instagram.png';
import flickr from '../images/flickr.png';
import vero from '../images/vero.png';
import mastodon from '../images/mastodon-purple.png';
import twitter from '../images/twitter.png';
import sports from '../images/Cricket.jpg';
import wildlife from '../images/CrimpEar05.jpg';
import portrait from '../images/Portrait.jpg';
import product from '../images/Bloodstone01-square.jpg';
import events from '../images/EventPhotography.jpg';
import wallart from '../images/WallArt.jpg';




class HomePage extends Component {
  render() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div>&nbsp;</div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-7">
                        <Link to="/photography">
                            <img className="img-fluid rounded mb-3 mb-md-0" src={photography} alt="Professional Photographer" />
                        </Link>
                    </div>
                    <div className="col-sm-5">
                        <Link to="/photography"><h3>Professional Photographer</h3></Link>
                        <p>Over 45 years experience providing News, Sports, Weddings, Fashion, Corporate and Nature photography to many different clients worldwide.</p>
                        <Link to="/photography"><button className="btn btn-primary" variant="primary">Gallery</button></Link>
                        <div className="col-sm-5>"> 
                            <p>&nbsp;</p>
                        </div>
                        <div className="col-sm-5>">
                            <div className="d-flex">
                                <div className="col-sm-2">
                                    <a href="https://www.facebook.com/rob.miracle" target="_blank" rel="noreferrer">
                                        <img className="img-fluid" src={facebook} width="100%" alt="Follow me on Facebook" />
                                    </a>
                                </div>
                                <div className="col-sm-2">
                                    <a href="https://www.instagram.com/robmiraclephoto/" target="_blank" rel="noreferrer">
                                        <img className="img-fluid" src={instagram} width="100%" alt="Follow me on Instagram" />
                                    </a>
                                </div>
                                <div className="col-sm-2">
                                    <a href="https://www.flickr.com/photos/yophotodude/" target="_blank" rel="noreferrer">
                                        <img className="img-fluid" src={flickr} width="100%" alt="Follow me on Flickr" />
                                    </a>
                                </div>
                                <div className="col-sm-2">
                                    <a href="https://vero.co/robmiracle" target="_blank" rel="noreferrer">
                                        <img className="img-fluid" src={vero} width="100%" alt="Follow me on Vero" />
                                    </a>
                                </div>
                                <div className="col-sm-2">
                                    <a rel="me noreferrer" href="https://newsie.social/@robmiracle" target="_blank">
                                        <img className="img-fluid" src={mastodon} width="100%" alt="Follow me on Mastodon" />
                                    </a>
                                </div>
                                <div className="col-sm-2">
                                    <a rel="me noreferrer" href="https://twitter.com/robmiraclephoto" target="_blank">
                                        <img className="img-fluid" src={twitter} width="100%" alt="Follow me on Twitter" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                     <hr />
                    <div>&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <img className="img-fluid" src={sports} alt="Editorial and Sports Photography" />
                        <h3>Editorial and Sports Photography</h3>
                        <p>From breaking news to professional sports to your Mite hockey team, I have the experience to capture anything that moves and capture the defining moment of the event.</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={wildlife} alt="Nature and Travel Photoghraphy" />
                        <h3>Nature &amp; Travel Photography</h3>
                        <p>I'm also an experienced wildlife, nature and travel photographer. I'm available for contract on any of your needs. Need photos from a local or looking for great wildlife photos, just reach out!</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={portrait} alt="Creative Portraiture" />
                        <h3>Creative Portraiture</h3>
                        <p>If you want standard studio portraits, I'm not your photographer. If you want something with style and creativity, then contact me!</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <img className="img-fluid" src={product} alt="" />
                        <h3>Product Photography</h3>
                        <p>I am available for various commerical products for print, website, or social media use.</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid" src={events} alt="" />
                        <h3>Event Photography</h3>
                        <p>High quality photography for your events is available. From movement events to corpororate parties. I will deliver you great images for your event.</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid" src={wallart} alt="" />
                        <h3>Wall Art</h3>
                        <p>I have a wide range of existing photos that would look excellent on your wall, or I can be commissioned to produce just the right photo for you.</p>
                    </div>
                </div>

            </div>
        </div>
    );  
  }
}

export default HomePage;