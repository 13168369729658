import React, { Component } from 'react';
//import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Header from './components/header.js';
import Footer from './components/footer.js';

//import sports from './images/Cricket.jpg';
//import wildlife from './images/CrimpEar05.jpg';
//import portrait from './images/Portrait.jpg';
//import product from './images/Bloodstone01-square.jpg';
//import events from './images/EventPhotography.jpg';
//import wallart from './images/WallArt.jpg';


import cowboys from './images/cowboys_v_redskins.jpg';
import img01 from './images/2022_04_YavapaiPointSunrise03_WEB.jpg';
import img02 from './images/LakeClark-2-28_WEB.jpg';
import img03 from './images/2022_03_RedShoulder02_Crop_WEB.jpg';
import img04 from './images/Em01_WEB.jpg';
import img05 from './images/LakeClark-1-05_WEB.jpg';
import img06 from './images/Bloodstone01.jpg';
import img07 from './images/LakeClarkLandscape03_WEB.jpg';
import img08 from './images/2022_05_TigerLilyFlowerPorn03_WEB.jpg'
import img09 from './images/Kyara09_WEB.jpg';
import img10 from './images/2022_08_Lightning_v_Raptors11_WEB.jpg';
import img11 from './images/LakeClark-2-25_WEB.jpg';
import img12 from './images/2022_10_PuraVida03_WEB.jpg';
import img13 from './images/2022_11_Chimp01_WEB.jpg';
import img14 from './images/2022_12_NorthernMockingBird01_WEB.jpg';
import img15 from './images/FoggyHeron01_WEB.jpg';
import img16 from './images/2022_07_Indy01_WEB.jpg';
import img17 from './images/LakeClark-2-18_WEB.jpg';




// import lions from './images/lions.jpg';
// import hockey from './images/hockey.jpg';
// import cougar from './images/cougar.jpg';
// import horses from './images/horses.jpg';
// import swans from './images/chinese_swans.jpg'
// import coldMorning from './images/cold_morning.jpg';
// import miamiSkyline from './images/miami_skyline.jpg';
// import sailboat from './images/sailboat.jpg';
// import sunriseBalloons from './images/sunrise_balloons.jpg';
// import surferSuprise from './images/surfer_surprise.jpg';
// import yosemiteValley from './images/yosemite_valley.jpg';
// import lakeLure from './images/lake_lure.jpg';
// import cowboys from './images/cowboys_v_redskins.jpg';
// import momAndFoal from './images/mom_and_foal.jpg';
// import halfdome from './images/hiking_halfdome.jpg';
// import goldenGate from './images/golden_gate_bridge2.jpg';
// import suprisedSquirrel from './images/surprised_squirrel.jpg';

//import budgie from './images/BudgieParakeet02.jpg';
//import cardinal from './images/CardinalEating02.jpg';
//import landscape from './images/landscape.jpg';
//import goldfinch from './images/GoldFinch01.jpg';
//import whiteTiger from './images/white_tiger.jpg';
//import miamiSunrise from './images/miami_sunrise.jpg';
//import seal from './images/feeding_happiness.jpg';
//import butterfly from './images/butterfly.jpg';
//import lighthouseSunset from './images/lighthouse_sunset.jpg';
//import yosemiteSunset from './images/yosemite_sunset.jpg';

//let images = []

//function generateCarouselItems() {
//
//}

class Photography extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div>&nbsp;</div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <Carousel>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={cowboys}
                                    alt="NFL Football - Dallas at Washington"
                                />
                                <Carousel.Caption>
                                    <h3>NFL Football</h3>
                                    <p>Washington, DC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img01}
                                    alt="Sunrise at the Grand Canyon"
                                />
                                <Carousel.Caption>
                                    <h3>Sunrise over Yavipai Point</h3>
                                    <p>Grand Canyon, AZ</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img02}
                                    alt="Horned Puffin in flight"
                                />
                                <Carousel.Caption>
                                    <h3>Horned Puffin</h3>
                                    <p>Lake Clark National Park, AK</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img03}
                                    alt="Red-shouldered hawk closeup portrait"
                                    height="500px"
                                />
                                 <Carousel.Caption>
                                    <h3>Red-shouldered hawk</h3>
                                    <p>Cary, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img04}
                                    alt="Portait of a brunette woman"
                                    height="500px"
                                />
                                <Carousel.Caption>
                                    <h3>Portraiture</h3>
                                    <p>Lexington, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img05}
                                    alt="Closeup of a coastal brown bear looking at the camera"
                                    height="500px"
                                />
                                <Carousel.Caption>
                                    <h3>Coastal Brown Bear</h3>
                                    <p>Lake Clark National Park, AK</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img06}
                                    alt="Jewelry photography of a bloodstone bracelet"
                                />
                                <Carousel.Caption>
                                    <h3>Bloodstone Bracelet</h3>
                                    <p>Product Photography</p>
                                </Carousel.Caption>
                            </Carousel.Item>                            
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img07}
                                    alt="a lake with lily pads in Alaska"
                                />
                                <Carousel.Caption>
                                    <h3>Lily Pad Lake</h3>
                                    <p>Lake Clark National Park, AK</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img08}
                                    alt="Macro closeup of a day lily's stamen"
                                />
                                <Carousel.Caption>
                                    <h3>Day Lily</h3>
                                    <p>Cary, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img09}
                                    alt="Portrait of a woman lounging on a couch in red light"
                                />
                                <Carousel.Caption>
                                    <h3>Portraiture</h3>
                                    <p>Raleigh, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img10}
                                    alt="closeup action shot of a cricket batsman and catcher"
                                />
                                <Carousel.Caption>
                                    <h3>Professional Cricket</h3>
                                    <p>Morrisville, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img11}
                                    alt="Bald Eagle sitting on driftwood"
                                />
                                <Carousel.Caption>
                                    <h3>Bald Eagle</h3>
                                    <p>Lake Clark National Park, AK</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img12}
                                    alt="Aerial dance performance"
                                />
                                <Carousel.Caption>
                                    <h3>Aerial Dance Performance</h3>
                                    <p>Cary, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img13}
                                    alt="black and white portrait of a chimpanzee"
                                />
                                <Carousel.Caption>
                                    <h3>Chimpanzee</h3>
                                    <p>Asheboro, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img14}
                                    alt="Northern mockingbird singing in a winterberry bush"
                                />
                                <Carousel.Caption>
                                    <h3>Northern Mockingbird</h3>
                                    <p>Raleigh, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img15}
                                    alt="Great blue heron sitting on a branch in the middle of a foggy river"
                                />
                                <Carousel.Caption>
                                    <h3>Great Blue Heron</h3>
                                    <p>Moncure, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img16}
                                    alt="Indigo Bunting sitting on a corn stalk"
                                />
                                <Carousel.Caption>
                                    <h3>Indigo Bunting</h3>
                                    <p>Raleigh, NC</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img 
                                    className="d-block w-100"
                                    src={img17}
                                    alt="Coastal Brown Bear in meadow"
                                />
                                <Carousel.Caption>
                                    <h3>Coastal Brown Bear</h3>
                                    <p>Lake Clark National Park, AK</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>


                    </div>
                </div>
            </div>
            <Footer />
        </div>
        )
    }
}
  
export default Photography;