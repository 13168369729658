import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import HomePage from './components/homepage.js';

import './App.css';

class App extends Component {
  componentDidMount() {

  }

  render() {
    return (
      <div className="App">
        <Header />
        <HomePage />
        <Footer />
      </div>
    );  
  }
}

export default App;
