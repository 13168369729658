import React, { Component } from 'react';
//import './App.css';

class Footer extends Component {
  render() {
    return (
        <div className="container">
            <hr />
            <div className="row">
                <div className="col-sm-6">            
                    <p>Copyright &copy; 2001-2023, Rob Miracle. All Rights Reserved.</p>
                </div>
                <div className="col-sm-6">
                    <p>&nbsp;</p>
                </div>
            </div>
        </div>
    );  
  }
}

export default Footer;