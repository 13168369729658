import React, { Component, useState} from 'react';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Header from './components/header.js';
import Footer from './components/footer.js';
import './contact.css';
import { validEmail } from './Regex.jsx';

class Contact extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          yourname: '',
          email: '',
          subject: '',
          message: '',
          errormessage: '',
          haserrors: false,
          isVerified: false,
          isRobot: true,
          emailErr: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.recaptchaChange = this.recaptchaChange.bind(this);
    }
   

    validateEmail(email) {
        console.log(email);
        if (!validEmail.test(email)) {
            console.log("I think the email is invalid");
            this.setState({emailErr:true});
            alert("Your email address is invalid");
            return(false);
        } else {
            //alert("your email looks good");
            this.setState({emailErr:false});
            return(true);
        }
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        let errorMessage = '';
        let errorCondition = false;
        this.setState({errormessage:errorMessage, hasErrors: errorCondition});
        if (this.state.isRobot) {
            alert("No robots please");
            return;
        }
        this.setState({"message": errorMessage, haserrors: false});
        if (this.state.yourname.length < 3) {
            errorMessage = errorMessage + 'The Name field must contain at least 3 characters';
            errorCondition = true;
            this.setState({errormessage:errorMessage, hasErrors: errorCondition});
        }
        if (!this.validateEmail(this.state.email) ) {
            errorMessage = errorMessage + 'The email field is invalid';
            errorCondition = true;
            this.setState({errormessage:errorMessage, hasErrors: errorCondition});
        }
        if(errorCondition) {
            return;
        }
        console.log("attempting to fetch the contact script")

        var emailPackage = JSON.stringify(this.state);
        fetch('https://www.robmiracle.com/xyzzy/plugh/index.php', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: emailPackage
        }).then(res => res.json())
            .then(
                (result) => {
                    console.log("setting state");
                    this.setState({
                        isLoaded: true,
                        items: result.items
                    });
                    alert("Thank you for your note. We will get back with you soon!");
                    this.props.history.push('/');
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("got an error", error);
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );

        //alert("You are submitting " + this.state.email);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    recaptchaChange = (value) => {
        console.log("Captcha value:", value);
        if (value != null) {
            this.setState({isRobot:false});
        } else {
            this.setState({isRobot:true});
        }
    }
    
    render() {
        return (
            <div>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Contact Rob</h2>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <form>
                            <label htmlFor='yourname'>Name: <span className="required">*</span></label>
                            <input
                                name="yourname"
                                type="text"
                                value={this.state.yourname}
                                size="60"
                                required
                                placeholder = "Name"
                                onChange={this.handleInputChange} />
                            <br />
                            <label htmlFor='email'>Email: <span className="required">*</span></label>
                            <input
                                name="email"
                                type="email"
                                value={this.state.email}
                                size="60"
                                required
                                placeholder = "Email Address"
                                onChange={this.handleInputChange} />
                            <label htmlFor='subject'>Subject: <span className="required">*</span></label>
                            <input
                                name="subject"
                                type="subject"
                                value={this.state.subject}
                                size="60"
                                required
                                placeholder = "Subject"
                                onChange={this.handleInputChange} />
                            <label htmlFor='message'>Message: <span className="required">*</span></label>
                            <textarea name="message" 
                                value={this.state.message} 
                                onChange={this.handleInputChange}
                                required 
                                placeholder = "Enter your message here"
                                rows="10" 
                                cols="60"
                            />
                            <ReCAPTCHA
                                sitekey="6LfZhq0ZAAAAAHcAkXtfUYfVJTiPmVrSalWh0lsS"
                                onChange={this.recaptchaChange}
                            />
                            <p className="errorMessage">{this.state.errormessage}</p>
                            <button className="btn btn-primary" onClick={this.mySubmitHandler} type='submit'>Send Message</button>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        );  
    }
}

export default Contact;