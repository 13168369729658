import React, { Component } from 'react';
import Header from './components/header.js';
import Footer from './components/footer.js';
import portrait from './images/Kat01_1024.jpg';


class Trainer extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>Services</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-8">
                        <h4>Family Portraits and Bridal &amp; Engagement Sessions</h4>
                        <p><strong>$300</strong> - This is a one hour session. For family portraits, it will include multiple family groupings, such as parents alone, individual children, and the whole group. For extended/multiple families, an additional hour is $150.</p>
                        <h4>Fashion Portfolio Services</h4>
                        <p><strong>$300</strong> - This is a one hour session. The model can usually get three looks in. Studio/Venue rental fees are extra and are based on the venue. Styling available for an extra fee.</p>
                        <h4>Commercial / Product Photography</h4>
                        <p><strong>Varies</strong> - High quality commerical imagery for your products, editorial story telling, and other photo art for your business.</p>
                        <h4>Wall Art</h4>
                        <p><strong>Varies</strong> - Visit the <a href="https://photos.robmiracle.com" target="blank"  rel="noreferrer">Order Prints</a> link to purchase existing artwork for your home or office. If custom photography is needed, contact us to work out the specifics.</p>
                        <h4>Sporting Events</h4>
                        <p><strong>Varies</strong> - Single games, multi-athlete events (Marathons, cycling events, etc.), to full-day tournament photography is available. Contact us to discuss the scope of the event and deliverables expected for pricing.</p>
                    </div>
                    <div className="col-sm-4">
                        <img className="img-fluid rounded mb-3 mb-md-0" src={portrait} alt="Services" />
                    </div>
                </div>
   
                <div className="row">
                    <div className="col-sm-8">
                        <p></p>
                    </div>
                    <div className="col-sm-4" >
                    </div>            
                </div>
            </div>
            <Footer />
        </div>
    )
  }
}

export default Trainer;